<template>
  <v-data-table
    item-key="id"
    :headers="visibleHeaders.filter((h) => !dense || !h.denseHidden)"
    :footer-props="footerProps"
    :items="azureRetirements"
    :loading="loading"
    :server-items-length="azureRetirementsCount"
    :options.sync="options"
    must-sort
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template #top>
      <TextField
        autofocus
        prepend-inner-icon="$search"
        :placeholder="searchPlaceholder"
        hide-details
        v-model="searchText"
        clearable
      />
    </template>
    <template #[`item.header`]="{ item }">
      <v-row><b>{{ item.header }}</b></v-row>
      <!-- <v-row>{{ item.description }}</v-row> -->
      <v-row><span>[<a :href="item.url" target="blank">{{ item.url }}</a>]</span></v-row>
    </template>
    <template #[`item.modifiedOn`]="{ value }">{{ value | date }}</template>
    <template #[`item.targetRetirementDate`]="{ value }">{{
      value | date
    }}</template>
    <template #[`item.environments`]="{ item }">
      <AzureRetirementEnvironmentStatuses
        v-if="!item.isRemoved"
        class="text-no-wrap"
        :azureRetirement="item"
        @publish="$emit('publish', item)"
        @promote="$emit('promote', item)"
        @unpublish="$emit('unpublish', { environment: $event.environment, azureRetirement: item })"
      />
      <span v-else>{{ item.note }}</span>
    </template>
    <template #[`item.actions`]="{ item }">
      <v-btn
        v-if="!item.isRemoved"
        icon
        title="Archive"
        @click="$emit('archive', item)"
      >
        <v-icon small>$archive</v-icon>
      </v-btn>
      <v-btn v-else icon title="Restore" @click="$emit('restore', item)">
        <v-icon small>$restore</v-icon>
      </v-btn>
    </template>
  </v-data-table>
</template>

<script>
import AzureRetirementEnvironmentStatuses from './AzureRetirementEnvironmentStatuses.vue'
import TableMixin from '@/mixins/TableMixin'
import { debounce } from 'lodash-es'
import { createNamespacedHelpers } from 'vuex'
const { mapGetters, mapActions } = createNamespacedHelpers('AzureRetirements')

export default {
  mixins: [TableMixin],
  props: {
    target: String,
    dense: Boolean,
    itemsPerPage: {
      type: Number,
      default: 10
    }
  },
  components: {
    AzureRetirementEnvironmentStatuses
  },
  watch: {
    '$route.query.filter': {
      handler(newValue, oldValue) {
        this.options.page = 1
        if (newValue === 'Ready For Live' || oldValue === 'Ready For Live') {
          this.load()
        }
      }
    }
  },
  data() {
    return {
      options: {
        sortBy: ['modifiedOn'],
        sortDesc: [true],
        itemsPerPage: this.itemsPerPage
      }
    }
  },
  computed: {
    ...mapGetters(['azureRetirements', 'azureRetirementsCount', 'loading']),
    headers() {
      const headers = []

      headers.push(
        {
          text: 'Azure Retirement Update',
          value: 'header',
          searchable: false
        },
        {
          text: 'Target Retirement',
          value: 'targetRetirementDate'
        },
        {
          text: 'Target Retirement Year',
          value: 'targetRetirementYear',
          hidden: true,
          searchable: true
        },
        {
          text: 'Header',
          value: 'header',
          hidden: true,
          searchable: true
        },
        // {
        //   text: 'Description',
        //   value: 'description',
        //   hidden: true,
        //   searchable: true
        // },
        {
          text: 'Url',
          value: 'url',
          hidden: true,
          searchable: true
        }
      )

      headers.push({
        text: 'Modified on',
        value: 'modifiedOn',
        denseHidden: true
      })

      headers.push({
        text: 'Environments',
        value: 'environments',
        sortable: false,
        width: 1
      })

      headers.push({
        value: 'actions',
        sortable: false,
        width: 1,
        denseHidden: true
      })

      return headers
    }
  },
  methods: {
    ...mapActions(['getAzureRetirements']),
    load: debounce(function () {
      const params = this.oDataQuery
      this.getAzureRetirements(params)
    }, 200)
  }
}
</script>
