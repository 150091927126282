<template>
  <div>
    <AzureRetirementFilters />
    <AzureRetirementsTable
      ref="table"
      :items-per-page="100"
      :filter="filter"
      sync
      @publish="onPublish"
      @promote="onPromote"
      @unpublish="onUnpublish"
      @archive="onArchive"
      @restore="onRestore"
    />
  </div>
</template>

<script>
import AzureRetirementsTable from '../components/AzureRetirementsTable.vue'
import AzureRetirementFilters from '../components/AzureRetirementFilters.vue'
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
    AzureRetirementsTable,
    AzureRetirementFilters
  },
  computed: {
    ...mapGetters('AzureRetirements', ['getAzureRetirementFilterQuery']),
    filter() {
      const filters = []

      const azureRetirementFilterQuery = this.getAzureRetirementFilterQuery(
        this.$route.query.filter
      )

      if (azureRetirementFilterQuery) {
        filters.push(azureRetirementFilterQuery)
      }

      return filters.join(' and ')
    }
  },
  methods: {
    ...mapActions('AzureRetirements', ['publish', 'promote', 'unpublish', 'getFilterCounts', 'archive', 'restore']),
    async onPublish(azureRetirement) {
      await this.publish({
        id: azureRetirement.id,
        concurrency: azureRetirement.concurrency,
        environmentId: 1 // preview
      })
      await this.$refs.table.load()
      await this.getFilterCounts()
    },
    async onUnpublish(params) {
      const result = await this.$confirm(`Are you sure you want to unpublish the azure retirement from ${params.environment}?`, { title: 'Unpublish?' })
      if (result.value) {
        let id = params.azureRetirement.publishedAzureRetirementPreviewId
        if (params.environment === 'Live') {
          id = params.azureRetirement.publishedAzureRetirementLiveId
        }
        await this.unpublish(id)
        await this.$refs.table.load()
        await this.getFilterCounts()
      }
    },
    async onPromote(azureRetirement) {
      await this.promote({
        id: azureRetirement.publishedAzureRetirementPreviewId,
        concurrency: azureRetirement.publishedAzureRetirementPreviewConcurrency,
        environmentId: 2 // live
      })
      await this.$refs.table.load()
      await this.getFilterCounts()
    },
    async onArchive(azureRetirement) {
      const message = `Are you sure you want to archive the azure retirement '${azureRetirement.header}'?`
      const result = await this.$confirm(message, {
        title: 'Archive Azure Retirement?',
        buttonTrueText: 'Archive',
        buttonFalseText: 'Cancel',
        textBoxLabel: 'Note'
      })
      if (result.value) {
        azureRetirement.note = result.text
        await this.archive(azureRetirement)
        await this.getFilterCounts()
        await this.$refs.table.load()
      }
    },
    async onRestore(azureRetirement) {
      const message = `Are you sure you want to restore the azure retirement '${azureRetirement.header}'?`
      const result = await this.$confirm(message, {
        title: 'Restore Azure Retirement?',
        buttonTrueText: 'Restore',
        buttonFalseText: 'Cancel'
      })
      if (result.value) {
        await this.restore(azureRetirement)
        await this.getFilterCounts()
        await this.$refs.table.load()
      }
    }
  }
}
</script>
